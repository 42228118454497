import React from "react";
import { Link } from "react-router-dom";

const Menus = () => {
  return (
    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>

      <li>
        <Link to="/about">About us</Link>
      </li>

      <li className="has-dropdown">
        <Link to="/service">Services</Link>
        <ul className="submenu">
          <li>
            <Link to="/sapient-realestate">SAPIENT REAL ESTATE</Link>
          </li>
          <li>
            <Link to="/sapient-design">SAPIENT DESIGN</Link>
          </li>
          <li>
            <Link to="/sapient-creative">SAPIENT CREATIVE</Link>
          </li>
          <li>
            <Link to="/sapient-services">SAPIENT SERVICES</Link>
          </li>
          <li>
            <Link to="/sapient-consulting">SAPIENT CONSULTING</Link>
          </li>
        </ul>
      </li>

      <li>
        <Link to="/contact">Contact</Link>
      </li>


    </ul>
  );
};

export default Menus;
