import React, { useState } from "react";
import VideoPopup from "../../common/Modals/VideoPopup";

const ServiceDetailsAbout = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-1.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/consulting/2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">Business Details</h3>
                <p>
                  Emerging as a support services provider for group companies, with a particular focus on financial, legal, and European funds access sectors, Sapient Consulting has now broadened its horizon. At present, the firm diligently extends its services to all clients under the Sapient Group umbrella, ensuring an array of quality and essential services are readily available to all who require them.
                  <br /><br />
                </p>

                <h3>🌟 Key Contributions:</h3>
                <br />
                <p>
                  <b>⚖️ Foundational Support:</b> Sapient Consulting initiated its journey providing indispensable support to group firms, primarily those engaged in financial, legal, and European funds access.
                  <br />
                  <b>🚀 Expanded Service Portfolio:</b> The firm now proudly serves all Sapient Group clients, offering a myriad of services essential for various operational and strategic functions.
                  <br />
                  <b>🏛️ Service Spectrum:</b> Sapient Consulting is adept and proficient in offering consulting, assistance, and representation for various operations at the Trade Register Office.
                  <br />
                  <b>📝 Registration Services:</b> Providing meticulous services for registrations in the Trade Register for both individuals and legal entities, including the establishment of LLCs, Sole Proprietorships, Individual Enterprises, Family Enterprises, and more.
                  <br />
                  <b>🔧 Constitutional Document Amendments:</b> Expertly handling modifications to constitutional documents to ensure legal compliance and operational efficiency.
                  <br />
                  <b>💱 Capital Adjustments:</b> Assisting in both capital increases and reductions, providing necessary guidance and support during the process.
                  <br />
                  <b>🔄 Share Assignments & Partner Modifications:</b> Facilitating share assignments, partner entries and withdrawals, ensuring smooth transitions and legal adherence.
                  <br />
                  <b>👥 Administrative Changes:</b> Assisting in the appointment and revocation of administrators, ensuring seamless leadership transitions within organizations.
                  <br />
                  <b>🏢 Establishment & Dissolution:</b> Providing support for the establishment and dissolution of work points, branches, and subsidiaries.
                  <br />
                  <b>🚚 Address Changes & Contract Extensions:</b> Facilitating office relocations, address changes, and contract extensions with minimal disruption to business operations.
                  <br />
                  <b>📛 Company Name Changes:</b> Assisting in the legal and administrative process of changing a company’s name.
                  <br />
                  <b>📜 Operating Licenses:</b> Helping businesses obtain the necessary operating licenses to legally conduct their activities.
                  <br />
                  <b>🔍 Activity Objects:</b> Assisting in adding or removing business activities to comply with legal and operational requirements.
                  <br />
                  <b>🆔 Identification Data Modifications:</b> Helping update associate identification data as required.
                  <br />
                  <b>📇 Duplicate Certificate Acquisition:</b> Facilitating the acquisition of duplicate registration certificates (CUI).
                  <br />
                  <b>▶️ Activity Suspension & Resumption:</b> Assisting businesses during periods of activity suspension and resumption, providing legal and administrative support.
                  <br />
                  <b>📄 Certificate Issuance:</b> Assisting in obtaining various certificates and official documentation required for business operations.
                  <br />
                  <b>📚 Other Trade Register Operations:</b> Expertly handling any other registrations and operations at the Trade Register Office.
                </p>

                <br /><br />
                <h4 className="tp-sv__subtitle">
                  🌐 Integrated Project Services:
                </h4>
                <br />
                <p>
                  <b>🏭Architectural Design & Redesign:</b> Engaged in both designing and redesigning architectural projects, Sapient Consulting specializes in residential buildings, industrial structures, commercial and service buildings. They also handle building redesigns (facade redesigns, repartitioning, extensions, adding attics and floors), architectural surveys, operating permits, 3D and video simulations, authorizations, and permits, as well as technical documentation and detailing.
                </p>
                <p>
                  <b>🏗️ Structural Design:</b> Expertly providing structural design services to ensure stability and resistance in various building projects.
                </p>
                <p>
                  <b>🏙️Urban Planning Documentation:</b> Preparing detailed and zonal urban plans, and conducting feasibility studies to offer a comprehensive understanding of potential projects.
                </p>
                <p>
                  <b>🔥Technical Expertise:</b> Conducting technical expertise for building resistance and stability, as well as fire safety.
                </p>
                <p>
                  <b>🚒Fire Safety Scenarios:</b> Developing detailed and effective fire safety scenarios to ensure compliance and safety.
                </p>
                <p>
                  <b>🚧Site Consultation & Supervision:</b> Offering consultation, site monitoring, and site supervision services to ensure projects are executed effectively and efficiently.
                </p>
                <p>
                  <b>🚿Installations:</b> Designing thermal, sanitary, and electrical installations, providing comprehensive services for various building projects.
                </p>
                <p>
                  <b>🔋Energy Audits:</b> Conducting energy audits and providing energy certificates, Sapient Consulting helps businesses meet energy compliance and efficiency standards.
                </p>

                <div className="tp-sv__video">
                  <div
                    className="tp_sv__video-bg"
                    // data-background="/assets/img/service/service-blog-bg.jpg"
                    style={{
                      backgroundImage: `url("/assets/img/service/consulting/3.jpg")`,
                    }}
                  >
                    <div className="pt-180 pb-180 text-center p-relative">
                      <span>
                        <button
                          onClick={() => setIsVideoOpen(true)}
                          className="sv-video sv-popup-video"
                        >
                          <i className="fas fa-play"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      {/* video modal start */}
      < VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"wr3DtuCCc7U"}
      />
      {/* video modal end */}
    </>
  );
};

export default ServiceDetailsAbout;
