import React from "react";
import { ServiceItem } from "./ServiceItem";

const OurService = () => {
  return (
    <>
      <div className="tp-service-ara grey-bg-4 pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-70">
                <span className="tp-section-subtitle mb-30">OUR SERVICES</span>
                <h2 className="tp-section-title">Explore Our Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <ServiceItem
              icon="assets/img/icons/1.png"
              title="Sapient Real Estate"
              desc="Top Romanian real estate firm, Sapient Real Estate offers comprehensive services, excelling in portfolio management."
              link="/sapient-realestate"
            />
            <ServiceItem
              icon="assets/img/icons/2.png"
              title="Sapient Creative"
              desc="Sapient Creative champions soulful branding, crafting experiences rather than mere products, with passion in projects."
              link="/sapient-creative"
            />
            <ServiceItem
              icon="assets/img/icons/3.png"
              title="Sapient Design"
              desc="Delivering innovative, user-centric designs, Sapient Design crafts brands that connect, resonate, and engage effectively."
              link="/sapient-design"
            />
            <ServiceItem
              icon="assets/img/icons/4.png"
              title="Sapient Services"
              desc="Sapient Services offers tailored, comprehensive business solutions, emphasizing partnerships, quality, and continuous support for clients."
              link="/sapient-services"
            />
            <ServiceItem
              icon="assets/img/icons/5.png"
              title="Sapient Consulting"
              desc="Providing invaluable, steadfast support with a focus on financial, legal, and European funds, Sapient Consulting consistently excels."
              link="/sapient-consulting"
            />
            <ServiceItem
              icon="assets/img/icons/6.png"
              title="Sapient Group"
              desc="A conglomerate of excellence, Sapient Group delivers unparalleled services in consulting, real estate, and branding."
              link="/about"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OurService;
