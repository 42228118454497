import React, { useState } from "react";
import VideoPopup from "../../common/Modals/VideoPopup";

const ServiceDetailsAbout = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/imobiliare/1.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/imobiliare/2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">Business Details</h3>
                <p>
                  <b>SAPIENT REAL ESTATE</b> has consistently and meticulously crafted its path towards success, enjoying a robust growth trajectory while cementing its esteemed reputation as a top-tier company within the vibrant real estate sector in the beautiful country of Romania.
                  <br /><br />
                  A number of compelling factors contribute to the illustrious profile of SAPIENT REAL ESTATE:
                  <br />
                  <b>💪 Market Longevity:</b> The company boasts an impressive tenure in the market, having bravely and successfully weathered the stormy financial crisis that unfolded in 2008. This crisis was a challenging period for many, but SAPIENT REAL ESTATE emerged stronger and more resilient.
                  <br /><br />
                  <b>🌐 Client Portfolio:</b> SAPIENT REAL ESTATE takes pride in serving a diverse and prestigious set of clients. Within its prized portfolio, you’ll find illustrious names and esteemed organizations, including but not limited to <i>Eco Eastern Europe Real Estate AG, Krammer & Wagner Project Development and Real Estate GmbH, Krocon Holding GmbH, Raiffeisen Leasing Austria, and the noteworthy Danlac ApS.</i>
                  <br /><br />
                  <b>🏡 Real Estate Projects:</b> The company is renowned for undertaking and executing real estate projects of significant scale and impact. Among the constellation of its projects, Vienna Residence shines the brightest. This particular endeavor represents the largest residential complex situated in the charming city of Oradea, offering a harmonious blend of luxury and comfort for its residents.
                  <br /><br />
                  Furthermore, SAPIENT REAL ESTATE is not just a real estate giant; it is a comprehensive service provider offering a suite of services designed to cater to the multifaceted needs of its clients:
                  <br />
                  <b>🤝 Real Estate Brokerage :</b> Connecting buyers and sellers in the dynamic real estate market, facilitating transactions that are seamless and rewarding for all parties involved.
                  <br />
                  <b>🏢 Leasing Services :</b> Whether you are looking for commercial spaces, sophisticated offices, or expansive warehouses, the company provides leasing options that are flexible and tailored to the unique needs of its clients.
                  <br />
                  <b>🌆 Project Development :</b> From conception to completion, SAPIENT REAL ESTATE is involved in the development of transformative real estate projects that redefine landscapes and contribute positively to communities.
                  <br />
                  <b>💰 Real Estate Evaluations :</b> Offering expert evaluations of real estate properties, ensuring that clients are well-informed and can make decisions that are financially sound and strategically astute.
                  <br />
                  <b>🌾 Agricultural Land Consolidation :</b> For those invested in the agricultural sector, the company offers services related to the consolidation of agricultural lands, optimizing the value and productivity of these vital assets.
                  <br />
                  <b>📈 Investment Consultancy :</b> With a deep well of knowledge and insight, the company provides invaluable consultancy services for individuals and entities looking to invest in the lucrative real estate market, guiding them towards opportunities that are fruitful and sustainable.
                  <br /><br />
                  At SAPIENT REAL ESTATE, every client is valued, every project is a masterpiece, and every service is delivered with excellence and integrity. Join hands with a company that is as committed to your success as you are!
                </p>

                <div className="tp-sv__video">
                  <div
                    className="tp_sv__video-bg"
                    // data-background="/assets/img/service/service-blog-bg.jpg"
                    style={{
                      backgroundImage: `url("/assets/img/service/imobiliare/3.jpg")`,
                    }}
                  >
                    <div className="pt-180 pb-180 text-center p-relative">
                      <span>
                        <button
                          onClick={() => setIsVideoOpen(true)}
                          className="sv-video sv-popup-video"
                        >
                          <i className="fas fa-play"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"9nBld9fzdH8"}
      />
      {/* video modal end */}
    </>
  );
};

export default ServiceDetailsAbout;
