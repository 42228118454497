import React, { useState } from "react";
import VideoPopup from "../../common/Modals/VideoPopup";

const ServiceDetailsAbout = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/creative/1.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/creative/3.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">Business Details</h3>
                <p>
                  <b>SAPIENT CREATIVE</b> supports companies in developing a soulful brand, capable of offering customers experiences, not just products.
                  <br />
                </p>
                <h4 className="tp-sv__subtitle">🚀Reasons to Partner with Us:</h4>
                <p>
                  &bull; <b>Passionate Projects:</b> We infuse passion into all of our projects, ensuring each endeavor is approached with enthusiasm and commitment.
                  <br />
                  &bull; <b>Customer Experiences:</b> Our focus extends beyond products; we assist companies in delivering memorable and engaging customer experiences.
                  <br />
                  &bull; <b>Emotional Branding:</b> We develop brands that can establish deep emotional connections with their customers, fostering loyalty and satisfaction.
                  <br />
                  &bull; <b>Integrated Marketing:</b> Offering comprehensive marketing services, both online and offline, we provide a seamless and effective marketing strategy tailored to your brand.
                  <br />
                  &bull; <b>Marketing Process Implementation:</b> From marketing audits and solution identification to implementation and results measurement, we ensure the entire marketing process is executed efficiently, setting the stage for sustained success.
                  <br />
                  &bull; <b>Training & Internal Marketing:</b> Through our internal marketing and training programs, we educate and guide your employees to be customer-oriented, fostering customer loyalty and brand allegiance.
                  <br />
                  &bull; <b>Partners, Not Just Clients:</b> At SAPIENT CREATIVE, we seek to establish partnerships rather than client relationships, ensuring a collaborative and mutually beneficial engagement.
                </p>

                <h4 className="tp-sv__subtitle">
                  🌟 Services Offered:
                </h4>
                <p>
                  &bull; <b>Brand Creation & Communication Strategy:</b> Crafting unique brands and devising communication strategies that resonate with your target audience.
                  <br />
                  &bull; <b>Social Media Advertising:</b> Utilizing the power of social media platforms to enhance your brand's online presence and engage with your audience.
                  <br />
                  &bull; <b>Experiential Marketing:</b> Designing marketing strategies that offer immersive and memorable experiences for your customers.
                  <br />
                  &bull; <b>Events Marketing:</b> Planning and executing marketing events that effectively promote your brand and engage your audience.
                  <br />
                  &bull; <b>Public Relations:</b> Managing your brand’s public image and fostering positive relationships with media and the public.
                  <br />
                  &bull; <b>Marketing Audit:</b> Conducting thorough marketing audits to assess your brand’s current marketing strategies and identify areas for improvement.
                  <br />
                  &bull; <b>Internal Marketing:</b> Implementing internal marketing strategies to enhance employee engagement and foster a customer-centric organizational culture.
                  <br /><br />
                  Engage with SAPIENT CREATIVE and embark on a journey towards building a brand that not only sells but also connects and resonates with the hearts of your customers!
                </p>

                <div className="tp-sv__video">
                  <div
                    className="tp_sv__video-bg"
                    // data-background="/assets/img/service/service-blog-bg.jpg"
                    style={{
                      backgroundImage: `url("/assets/img/service/creative/2.jpg")`,
                    }}
                  >
                    <div className="pt-180 pb-180 text-center p-relative">
                      <span>
                        <button
                          onClick={() => setIsVideoOpen(true)}
                          className="sv-video sv-popup-video"
                        >
                          <i className="fas fa-play"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"g84kJ9_dRkA"}
      />
      {/* video modal end */}
    </>
  );
};

export default ServiceDetailsAbout;
