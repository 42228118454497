import React, { useState } from "react";
import VideoPopup from "../../common/Modals/VideoPopup";

const ServiceDetailsAbout = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/services/2.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/services/1.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">Business Details</h3>
                <p>
                  <b>SAPIENT SERVICES</b> is your go-to partner for comprehensive and tailored business solutions, committed to supporting companies in navigating through their unique challenges and seizing opportunities with confidence and expertise.
                </p>
                <br />
                <h4 className="tp-sv__subtitle">🚀Reasons to Partner with Us:</h4>
                <p>
                  &bull; <b>Customized Solutions:</b> We understand that each business is unique. Sapient Services provides tailored solutions designed to meet your specific needs and objectives, ensuring optimal outcomes and value.
                  <br />
                  &bull; <b>Customized Solutions:</b> We understand that each business is unique. Sapient Services provides tailored solutions designed to meet your specific needs and objectives, ensuring optimal outcomes and value.
                  <br />
                  &bull; <b>Expert Team:</b> With a cadre of seasoned professionals, our team brings a wealth of knowledge and expertise to the table, offering insights and strategies that are both innovative and practical.
                  <br />
                  &bull; <b>Client-Centric Approach:</b> Your success is our priority. We engage closely with our clients to understand their vision and challenges, delivering services that are not only effective but also aligned with their goals.
                  <br />
                  &bull; <b>Holistic Service Offering:</b> From consultancy to implementation, we offer a wide range of services that cover various facets of business operations and strategy, providing you with a one-stop-shop for all your business solution needs.
                  <br />
                  &bull; <b>Quality Assurance:</b> Commitment to excellence is ingrained in our DNA. We maintain the highest standards of quality and integrity in all our engagements, ensuring that you receive services that are not just satisfactory but exemplary.
                  <br />
                  &bull; <b>Continuous Support:</b> Our support extends beyond project completion. We offer ongoing support and advisory services to help you navigate through changing business landscapes and evolving challenges.
                  <br />
                  &bull; <b>Partnership Ethos:</b> At Sapient Services, we believe in building lasting partnerships with our clients. We invest in understanding and supporting your business as it grows and evolves, fostering a relationship that is mutually beneficial and sustainable.
                </p>

                <h4 className="tp-sv__subtitle">
                  🌟 Services Offered:
                </h4>
                <p>
                  &bull; <b>Business Consultancy:</b> Offering strategic advice and insights to help you make informed business decisions and develop effective strategies for growth and sustainability.
                  <br />
                  &bull; <b>Operational Support:</b> Providing support in streamlining your business operations, improving efficiency, and enhancing productivity through innovative solutions and practices.
                  <br />
                  &bull; <b>Financial Advisory:</b> Delivering expert financial advice and planning services to support your business in managing its finances prudently and strategically.
                  <br />
                  &bull; <b>Market Research & Analysis:</b> Conducting thorough market research and analysis to provide you with valuable insights into market trends, opportunities, and competitive landscape.
                  <br />
                  &bull; <b>Human Resources Management:</b> Supporting your business in recruiting, developing, and managing your human resources effectively, fostering a workplace that is productive and harmonious.
                  <br />
                  &bull; <b>Legal & Compliance Advisory:</b> Offering legal and compliance advisory services to ensure that your business operates ethically and in accordance with regulatory requirements.
                  <br />
                  &bull; <b>Technology Solutions:</b> Providing cutting-edge technology solutions designed to optimize your business processes, improve efficiency, and enhance your service delivery.
                  <br /><br />
                  Engage with SAPIENT SERVICES and experience a partnership that empowers your business to reach new heights of success through strategic, thoughtful, and reliable business solutions!"
                </p>

                <div className="tp-sv__video">
                  <div
                    className="tp_sv__video-bg"
                    // data-background="/assets/img/service/service-blog-bg.jpg"
                    style={{
                      backgroundImage: `url("/assets/img/service/services/3.jpg")`,
                    }}
                  >
                    <div className="pt-180 pb-180 text-center p-relative">
                      <span>
                        <button
                          onClick={() => setIsVideoOpen(true)}
                          className="sv-video sv-popup-video"
                        >
                          <i className="fas fa-play"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"2gIW-dmj4aw"}
      />
      {/* video modal end */}
    </>
  );
};

export default ServiceDetailsAbout;
