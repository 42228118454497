import React, { useState } from "react";
import VideoPopup from "../../common/Modals/VideoPopup";

const ServiceDetailsAbout = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/design/1.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/design/2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">Business Details</h3>
                <p>
                  <b>SAPIENT DESIGN</b> epitomizes the pinnacle of architectural innovation and thoughtful design solutions, aiding clients in manifesting structures that are not only architectural marvels but also spaces that echo with resonance and rich experiences.
                </p>
                <br />
                <h4 className="tp-sv__subtitle">🚀Reasons to Partner with Us:</h4>
                <p>
                  &bull; <b>Architectural Innovation:</b> Our ethos is deeply rooted in continuous innovation. Every architectural project undertaken is approached with a fresh, inventive perspective delivering unparalleled and progressive design solutions.
                  <br />
                  &bull; <b>User-Centric Architecture:</b> With the end-users in mind, we craft structures that are not only visually striking but also functional and intuitive, promising an enhanced living and spatial experience.
                  <br />
                  &bull; <b>Holistic Architectural Services: </b> From conceptual sketches to project execution, we offer a comprehensive suite of architectural services ensuring your project is coherent and consistent from start to finish.
                  <br />
                  &bull; <b>Sustainable Practices:</b> Sustainability is at the core of our designs. We are committed to employing eco-friendly practices and materials, crafting spaces that are environmentally responsible and aesthetically pleasing.
                  <br />
                  &bull; <b>Collaborative Endeavors:</b> Collaboration is key. We engage closely with our clients, absorbing their vision and expectations, and delivering architectural solutions that mirror their brand personality and values accurately.
                  <br />
                  &bull; <b>Partnership Focus:</b> We perceive our clients as partners in creation. Engaging with Sapient Design means entering a partnership focused on trust, transparency, and shared visions of success.
                </p>

                <h4 className="tp-sv__subtitle">
                  🌟 Services Offered:
                </h4>
                <p>
                  &bull; <b>Architectural Design & Planning:</b> We craft distinctive, functional, and sustainable designs that embody your vision while meeting the needs of the end-users effectively.
                  <br />
                  &bull; <b>Interior Design:</b> Our team delivers innovative and responsive design solutions for interiors, creating spaces that are reflective of your brand while enhancing user comfort and experience.
                  <br />
                  &bull; <b>Landscape Architecture:</b> Creating outdoor spaces that are not only beautiful and sustainable but also functional and aligned with the overall architectural design and brand identity.
                  <br />
                  &bull; <b>Urban Design & Planning:</b> Developing comprehensive urban designs and plans that contribute positively to the community and environment while reflecting your brand’s identity and values.
                  <br />
                  &bull; <b>Consultation & Strategy:</b> Offering expert architectural advice and consultancy, guiding you through the intricate process of planning, designing, and executing architectural projects seamlessly.
                </p>

                <div className="tp-sv__video">
                  <div
                    className="tp_sv__video-bg"
                    // data-background="/assets/img/service/service-blog-bg.jpg"
                    style={{
                      backgroundImage: `url("/assets/img/service/design/3.jpg")`,
                    }}
                  >
                    <div className="pt-180 pb-180 text-center p-relative">
                      <span>
                        <button
                          onClick={() => setIsVideoOpen(true)}
                          className="sv-video sv-popup-video"
                        >
                          <i className="fas fa-play"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"-vwOo9HSgco"}
      />
      {/* video modal end */}
    </>
  );
};

export default ServiceDetailsAbout;
