import React from "react";
import { Link } from "react-router-dom";

const TopAbout = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-140">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="tp-about-left pr-90">
                <div className="tp-section-wrapper pt-5">
                  <span className="tp-section-subtitle">
                    we increase your business
                  </span>
                  <h2 className="tp-section-title pt-25 mb-30">
                    Don’t worry Your project in the right hands
                  </h2>
                  <p>
                    Entrusting your project to Sapient Group means placing it in hands that are not only capable but also deeply committed to your success. With over two decades of proven expertise and a rich legacy of facilitating business growth, Sapient Group has been a reliable partner for countless companies navigating the complex terrain of the business world. From the nascent stages of a company to its maturity, we are dedicated to providing support that is not just comprehensive but also meticulously tailored to meet the unique needs and challenges of each partner.
                  </p>
                  <p className="pt-10">
                    Our approach is deeply rooted in a set of core values that not only guide our operations but also define our relationships with clients. We believe in fostering a sense of community and shared success, understanding that the triumph of individual companies contributes to the flourishing of the business environment as a whole. Ethical practices are non-negotiable for us, as we engage with clients, employees, and partners with utmost integrity and respect.
                  </p>
                </div>
                <br />
                <br />
                {/* <div className="mt-45 mb-30">
                  <Link to="/about">
                    <div className="tp-theme-btn d-flex align-items-center">
                      <div className="tp-btn-inner">
                        <p className="mb-0">Let’t Talk</p>
                        <b>Get in Touch</b>
                        <span className="d-inline-block">
                          <svg
                            width="46"
                            height="8"
                            viewBox="0 0 46 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                              fill="currentColor"
                            />
                          </svg>
                          <svg
                            width="46"
                            height="8"
                            viewBox="0 0 46 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img text-md-end">
                  <img src="assets/img/about/about.jpg" alt="" />
                </div>
                <div className="tp-about-info text-center grey-bg-4">
                  <h3>25</h3>
                  <h4>
                    Years of <br />
                    Experience
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about about end--> */}
    </>
  );
};

export default TopAbout;
