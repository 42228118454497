const menu_data = [
  {
    id: 1,
    title: 'Home',
    link: '/',
  },

  {
    id: 2,
    title: 'About',
    link: '/about',
  },

  {
    id: 3,
    hasDropdown: true,
    title: 'Services',
    link: '/service',
    submenus: [
      { title: 'SAPIENT REAL ESTATE', link: '/sapient-realestate' },
      { title: 'SAPIENT CONSULTING', link: '/sapient-consulting' },
      { title: 'SAPIENT CREATIVE', link: '/sapient-creative' },
      { title: 'SAPIENT DESIGN', link: '/sapient-design' },
      { title: 'SAPIENT SERVICES', link: '/sapient-services' },
    ]
  },

  {
    id: 6,
    title: 'Contact',
    link: '/contact',
  },
]

export default menu_data;
