import React from "react";

const AboutCompany = () => {
  return (
    <>
      <div className="about-details-page pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-details-info pr-65">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-25">
                    About our Company
                  </span>
                  <h2 className="tp-pt-size mb-40">
                    Our business boasts top of the line services
                  </h2>
                </div>
                <div className="about-tb-content">
                  <nav>
                    <div className="nav mb-30" id="nav-tab" role="tablist">
                      <button
                        className="nav-links active"
                        id="nav-history-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-history"
                        type="button"
                        role="tab"
                        aria-controls="nav-value"
                        aria-selected="true"
                      >
                        History
                      </button>
                      <button
                        className="nav-links"
                        id="mission"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-mission"
                        type="button"
                        role="tab"
                        aria-controls="nav-mission"
                        aria-selected="false"
                      >
                        Our Mission
                      </button>

                      <button
                        className="nav-links"
                        id="nav-vission-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-vission"
                        type="button"
                        role="tab"
                        aria-controls="nav-vission"
                        aria-selected="false"
                      >
                        Our Vission
                      </button>
                      <button
                        className="nav-links"
                        id="nav-value-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-value"
                        type="button"
                        role="tab"
                        aria-controls="nav-value"
                        aria-selected="false"
                      >
                        Our Values
                      </button>
                    </div>
                  </nav>

                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-history"
                      role="tabpanel"
                      aria-labelledby="nav-history"
                    >
                      <p>
                        Founded in 1998, Sapient initially entered the market by trading construction materials. Over the years, the company experienced significant growth and diversification. By 2005, real estate became its predominant focus, engaging in brokering, consulting, and development of various projects, either independently or in partnership. Sapient boasts a portfolio of significant developments, including Viena Residence, the largest residential complex in Oradea, and the revitalization of notable shopping centers.
                      </p>
                      <p>
                        The company's credibility has fostered collaborations with renowned western trusts seeking expansion into Romania's real estate market, working with notable clients like Eco Eastern Europe Real Estate AG and Raiffeisen Leasing Austria. In 2017, recognizing the opportunity to offer integrated business services, Sapient developed divisions like Sapient Consulting and Sapient Creative, celebrating 20 years in 2018 by enhancing client competitiveness in an increasingly global economy.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-mission"
                      role="tabpanel"
                      aria-labelledby="nav-mission"
                    >
                      <p>
                        Sapient Group is devoted to empowering the business community by providing integrated services that enhance the competitiveness of partner companies in an increasingly challenging market. Our mission is to support a company from its inception, facilitating its growth, and standing by its side through development and maturation phases.
                      </p>

                      <p>
                        Through our range of services, we not only assist in the birth and expansion of companies but also play a pivotal role in their ongoing development and success. With a focus on fostering competitiveness, Sapient Group is a steadfast ally for businesses at every stage, ensuring they have the tools, support, and expertise necessary to thrive in today’s dynamic business landscape.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-vission"
                      role="tabpanel"
                      aria-labelledby="nav-vission-tab"
                    >
                      <p>
                        Sapient envisions itself as a harmonious family that balances each employee's unique personality with the necessity of a united team adhering to shared values and principles. The company offers a dynamic and engaging working environment, perfect for energetic individuals seeking to escape job monotony. Employees at Sapient have the opportunity to explore beautiful homes, share ideas, discuss new offers, and develop both financially and emotionally every day.
                      </p>
                      <p>
                        The company fosters continuous learning, communication skills enhancement, trust-building, and calm maintenance in challenging situations. With over 20 years in the market, Sapient provides job stability, a prestigious reputation due to quality clients and significant projects, continuous professional development, and a favorable company location in Oradea's center, making it accessible for any meeting. The company eagerly welcomes new members to join its expanding team, offering a competitive financial package.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-value"
                      role="tabpanel"
                      aria-labelledby="nav-value-tab"
                    >
                      <p>
                        Sapient Group is steadfastly committed to core values that guide our actions and decisions. First and foremost is Community; we believe individual and company success is closely intertwined with the prosperity of the community they engage with, aspiring for successful individuals and businesses within thriving communities. Partnership is central, emphasizing long-term relationships over transient transactions, aiming to offer maximum value to our collaborators.
                      </p>

                      <p>
                        We staunchly promote Ethical Values, ensuring moral integrity in interactions with employees, clients, and partners. With a Client-Oriented focus, we prioritize understanding and meeting client needs, providing tailored solutions. Finally, our commitment to Integrated Services means clients can find nearly all necessary services for their business development under one roof, saving them both time and money. These guiding principles underpin our operations, driving our commitment to delivering unmatched value and service.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="tp-about-img-box p-relative d-inline-block mb-30">
                <div className="about-page-img">
                  <img src="assets/img/about/about-page.png" alt="" />
                </div>
                <div className="dots-img">
                  <img src="assets/img/about/dot.jpg" alt="" />
                </div>
                <div className="about-info-box d-flex flex-column justify-content-center text-center">
                  <h3 className="box-title">25</h3>
                  <h4 className="box-subtitle">
                    Years of <br />
                    Experience
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
