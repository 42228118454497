import React from 'react';
import { Route, Routes } from "react-router-dom";
// internal
import BackToTop from '../components/BackToTop';
import AnimateMouse from '../components/common/AnimateMouse';
import ContextProvider from '../components/context/ContextProvider';
import Home from '../components/Home/Home';
import HomeThree from '../components/HomeThree/HomeThree';
import HomeTwo from '../components/HomeTwo/HomeTwo';
import About from '../components/Pages/About/About';
import Blog from '../components/Pages/Blog/Blog';
import BlogDetails from '../components/Pages/BlogDetails/BlogDetails';
import ContactUs from '../components/Pages/ContactUs/ContactUs';
import FAQ from '../components/Pages/FAQ/FAQ';
import Portfolio from '../components/Pages/Portfolio/Portfolio';
import PortfolioDetails from '../components/Pages/PortfolioDetails/PortfolioDetails';
import Price from '../components/Pages/Price/Price';
import Service from '../components/Pages/Service/Service';
import TeamDetails from '../components/Pages/TeamDetails/TeamDetails';
import TeamPage from '../components/Pages/TeamPage/TeamPage';
import SapientRealEstate from '../components/Pages/SapientRealEstate/SapientRealEstate';
import SapientConsulting from '../components/Pages/SapientConsulting/SapientConsulting';
import SapientCreative from '../components/Pages/SapientCreative/SapientCreative';
import SapientDesign from '../components/Pages/SapientDesign/SapientDesign';
import SapientServices from '../components/Pages/SapientServices/SapientServices';

const AppNavigation = () => {
  return (
    <ContextProvider>
      <AnimateMouse />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home-2" element={<HomeTwo />} />
        <Route path="/home-3" element={<HomeThree />} />
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/team-details" element={<TeamDetails />} />
        <Route path="/service" element={<Service />} />
        <Route path="/sapient-realestate" element={<SapientRealEstate />} />
        <Route path="/sapient-consulting" element={<SapientConsulting />} />
        <Route path="/sapient-creative" element={<SapientCreative />} />
        <Route path="/sapient-design" element={<SapientDesign />} />
        <Route path="/sapient-services" element={<SapientServices />} />
        <Route path="/price" element={<Price />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio-details" element={<PortfolioDetails />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<BlogDetails />} />
      </Routes>
      <BackToTop />
    </ContextProvider>
  );
};

export default AppNavigation;