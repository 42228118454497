import React from "react";
import GoogleMapReact from 'google-map-react';

export default function GoogleMap() {
    const defaultProps = {
        center: {
            lat: 47.05421829223633,
            lng: 21.9290714263916
        },
        zoom: 15,
    };

    // Function to create the default Google Maps marker
    const handleApiLoaded = (map, maps) => {
        new maps.Marker({
            position: defaultProps.center,
            map: map,
            title: "My Marker"
        });
    };

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            />
        </div>
    );
}
