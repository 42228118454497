import React from 'react';
import { Link } from 'react-router-dom';

const FooterThree = () => {
  return (
    <footer>
      <div className="tp-footer__area black-bg">
        <div className="tp-footer">
          {/* <!-- main-footer start  --> */}
          <div className="tp-footer__main">
            <div className="container">
              <div className="tp-footer-border pt-100 pb-70">
                <div className="pb-30 pb-70">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="footer-logo mb-30">
                        <Link to="/" href="#"><img src="/assets/img/logo/logo.png" alt="" /></Link>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 text-end">
                      <div className="footer-form-2 mb-30">
                        <form>
                          <input type="email" placeholder="Enter your mail" />
                          <button type="submit">Subscribe <i
                            className="fal fa-paper-plane"></i></button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 pb-30">
                      <h3 className="tp-footer__widget-title text-white">Our Location</h3>
                      <ul>
                        <li><a href="#">London</a></li>
                        <li><a href="#">America</a></li>
                        <li><a href="#">Constarica</a></li>
                        <li><a href="#">Germany</a></li>
                        <li><a href="#">India</a></li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 pb-30">
                      <h3 className="tp-footer__widget-title text-white">Our Service</h3>
                      <ul>
                        <li>
                          <a href="/sapient-realestate">Spaient Real Estate</a>
                        </li>
                        <li>
                          <a href="/sapient-creative">Sapient Creative</a>
                        </li>
                        <li>
                          <a href="/sapient-design">Sapient Design</a>
                        </li>
                        <li>
                          <a href="/sapient-services">Sapient Services</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="tp-footer__widget tp-footer__2  pb-30">
                      <h3 className="tp-footer__widget-title text-white">Quick Links</h3>
                      <ul>
                        <li>
                          <a href="about">About Us</a>
                        </li>
                        <li>
                          <a href="portfolio">Portfolio</a>
                        </li>
                        <li>
                          <a href="blog">Blog</a>
                        </li>
                        <li>
                          <a href="contact">Contact</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2  pb-30">
                      <h3 className="tp-footer__widget-title text-white">Quick Links</h3>
                      <ul>
                        <li><a href="https://www.google.com/maps/dir//Strada+Vasile+Alecsandri+1,+Oradea+410072/@47.0541871,21.8466704,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x474647e89ec94beb:0x4ccd041dd32ef03e!2m2!1d21.9290709!2d47.0542167?entry=ttu" target="_blank" rel="noopener noreferrer">Str. Vasile Alecsandri nr.1, Oradea, Bihor</a></li>
                        <li><a href="tel:+40359405677">+40 359 405 677</a></li>
                        <li><a href="#">office@sapientgroup.ro</a></li>
                        <li><span> Office Hours: 9AM - 5PM</span></li>
                        <li><span> Monday - Friday </span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- main footer end  --> */}
        </div>
      </div>

      {/* <!-- footer copy right --> */}
      <div className="top-footer-copyright pt-30 pb-30 black-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-copyrigh-text text-center text-white">
                <span>© {new Date().getFullYear()} Sapient - Business Consulting. All Rights Reserved.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- footer copyright end  --> */}
    </footer>
  );
};

export default FooterThree;